import React from 'react';
import ReactDOM from 'react-dom/client';
import { TonConnectUIProvider } from '@tonconnect/ui-react';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <TonConnectUIProvider manifestUrl="https://notshop.pro/assets/tonconnect-manifest.json" 
      actionsConfiguration={{
        twaReturnUrl: 'https://t.me/TestNotShop_Bot/app'
      }}>{
  <React.StrictMode>
    <App />
  </React.StrictMode>
  }</TonConnectUIProvider>
);
