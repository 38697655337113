import React, { useState } from 'react';

import './style.scss'

import { Router } from '../../components/Router/component'
import DropdownMenu from '../../components/dropdown/component'

import USD from '../../assets/images/usd.png';
import EUR from '../../assets/images/eur.png';
import RUB from '../../assets/images/rub.png';

import Arrow from '../../assets/images/arrow.png';
import Check from '../../assets/images/check.png';

const Hello = (props) => {

    const [page, setPage] = useState('first')

    const go = to => () => {
        setPage(to);
    }

    const options = [
        { value: 'rubles', label: 'RUB', sublabel: 'Russian ruble', image: RUB },
        { value: 'dollar', label: 'USD', sublabel: 'Dollar US', image: USD },
        { value: 'euro', label: 'EUR', sublabel: 'Euro', image: EUR }
      ];

      const [selectedOption, setSelectedOption] = useState(options[0]);

      const customRender = (option, subtitle = true) => (
        <div className="custom-option">
            {subtitle && (<img className='option__image' src={option.image}/>)}
            <div className='option__text-holder'>
                <span className='option__title'>{option.label}</span>
                {subtitle && (<span className='option__subtitle'>{option.sublabel}</span>)}
            </div>
            {(selectedOption.value === option.value && subtitle) && (<img className='check' src={Check}/>)}
        </div>
      );
    
      const handleSelect = (option) => {
        setSelectedOption(option);
      };

    return (

        <Router active={page}>
            <div className='hello__background' id="first">
                <div className='line__wrapper'>
                    <div className='lines'>
                        <div className='line filled'></div>
                        <div className='line'></div>
                    </div>      
                </div>
                
                <div className='content__wrapper'>
                    <span className='header__text'>Hi!<br/>Welcome to the<br/>Not Shop</span>
                    <br/>
                    <span className='subtitle'>This is the first and most convenient platform to exchange NOT, TON and USDT. Exchange cryptocurrency and transfer directly to your bank account!</span>
                </div>
                
                <div className='next__button'>
                    <span onClick={go('second')}>Next</span>
                </div>
            </div>

            <div className='hello__background' id="second">
                <div className='line__wrapper'>
                    <div className='lines'>
                        <div className='line'></div>
                        <div className='line filled'></div>
                    </div>
                    
                </div>
                
                <div className='content__wrapper'>
                    <span className='subtitle'>First of all, choose the default currency </span>
                </div>
                <div className='dropdown__wrapper'>
                    <DropdownMenu className="dropdown-menu" options={options} defaultValue={options[0]} onSelect={handleSelect} customRender={customRender} />
                </div>
                
                <div className='next__button'>
                <span onClick={props.go('home')}>Next</span>
                </div>
            </div>
        </Router>
    )
}   

export default Hello;