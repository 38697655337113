import React, { useState, useEffect } from 'react';
import telegram from './assets/telegram';
import eruda from '../node_modules/eruda/eruda';
import axios from '../node_modules/axios/index';
import { TonConnectButton, useTonWallet, useTonAddress } from '@tonconnect/ui-react';
import TonConnect from '@tonconnect/sdk';

import './assets/css/App.scss';

import { Router } from './components/Router/component';

import Hello from './panels/hello/Hello';
import Home from './panels/home/component';
import Settings from './panels/settings/component';
import ChangeCurrency from './panels/change/component';
import Referral from './panels/referral/component';
import History from './panels/history/component';
import Default from './panels/default/component';
import FrensStat from './panels/FrensStats/component';
import Withdraw from './panels/referral-withdraw/component';
import Wallets from './panels/wallets/component';

import NOT from './assets/images/banners/not.png'
import SBP from './assets/images/banners/sbp-rub.png'
import Arrow from './assets/images/Arrow.svg'

const DEBUG_MODE = false

const App = () => {

  const [panel, setPanel] = useState('home');
  const [modal, setModal] = useState(null)
  const [payload, setPayload] = useState(null)
  const [tg, setTg] = useState(window.Telegram.WebApp)
  const [meta, setMeta] = useState({
    parsed: false,
    platform: '',
    first_name: 'username',
    id: 0,
    is_premium: false,
    language_code: 'ru',
    last_name: '',
    username: ''
  });

  const [serverCurrencies, setServerCurrencies] = useState(null)
  const [serverWallets, setServerWallets] = useState(null);

  function parseStringToJSON(inputString) {
    // Извлекаем только часть с пользователем из строки
    var userDataString = inputString.match(/user=(.*?)&/)[1];

    // Декодируем URL и заменяем экранированные символы
    userDataString = decodeURIComponent(userDataString).replace(/%3A/g, ':').replace(/%2C/g, ',').replace(/%22/g, '"').replace(/%7B/g, '{').replace(/%7D/g, '}').replace(/%3D/g, '=').replace(/%26/g, '&');

    // Парсим строку с данными пользователя в JSON объект
    return JSON.parse(userDataString);
}

  const [currencies, setCurrencies] = useState({
    from: {name: "NOT", image: NOT, value: 'not'},
    to: {name: "RUB", image: SBP, value: 'sbp', fullname: "phone number"}
  })

  async function getCurrencies() {
    let data = JSON.stringify({
      "secret_key": "FWyd3fjtVwWN0AXkfFtxGWAsSHuj2w"
    });
    
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://apicrypto.tech/transactions/currencies/',
      headers: { 
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      },
      data : data
    };
    let response = await axios.request(config)
    setServerCurrencies(response.data.data.currencies) 
  }

  async function getWallets() {
    let data = JSON.stringify({
      "secret_key": "FWyd3fjtVwWN0AXkfFtxGWAsSHuj2w",
      "user_id": meta.id
    });
    
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://apicrypto.tech/users/wallets',
      headers: { 
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      },
      data : data
    };
    let response = await axios.request(config)
    setServerWallets(response.data.data.wallets) 
  }

  useEffect(() => {
    console.log(serverWallets)
  }, [serverWallets])

  async function registerUser() {
    let data = JSON.stringify({
      "secret_key": "FWyd3fjtVwWN0AXkfFtxGWAsSHuj2w",
      "user_id": Number(meta.id),
      "first_name": meta.first_name,
      "last_name": meta.last_name
    });
    
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://apicrypto.tech/users/register/',
      headers: { 
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      },
      data : data
    };
    let response = await axios.request(config)
    if(response.data.data.is_new) {
      setPanel('hello')
    }
  }

  useEffect(() => {
    registerUser()
    if(!DEBUG_MODE) {
    eruda.init()
      tg.expand();

      tg.MainButton.hide()
      const info = parseStringToJSON(decodeURI(tg.initData));

      setMeta({
        parsed: true,
        platform: tg.platform,
        first_name: info.first_name,
        id: info.id,
        is_premium: info.is_premium,
        language_code: info.language_code,
        last_name: info.last_name,
        username: info.username
      })
    } else {
      eruda.init()
    }


    
    getCurrencies()
    getWallets()
  }, [])

  const go = (to, payload) => () => {
    setPanel(to);
    setPayload(payload)
  }

  const updateCurrencies = (object) => {
    setCurrencies((prevState) => {
        return {
            ...prevState,
            ...object
        }
    })
    setPanel('home')
}

  const openModal = modal => () => {
    setModal(modal);
  }

  const openWithdrawModal = modal => () => {
    setModal(modal);
    setPanel('home')
  }

  const userFriendlyAddress = useTonAddress();

  const openCreateTransactionModal = (to, createTransaction) => {
    setModal(
      <div className='modal__content'>
        <div className='back-page__title'>
          <div className='back__button' onClick={openModal(null)}>
            <img src={Arrow}/>
          </div>
          <div className='change__title history__title'>Enter {to.fullname}</div>
        </div>
        <div className='wallets__block-wrapper'>
          <span>{to.fullname.charAt(0).toUpperCase() + to.fullname.slice(1)}</span>
          {!(['ton', 'not'].includes(to.value) && userFriendlyAddress) && <input placeholder='Enter' className='wallets__input-text' id='wallet'/>}
        </div>
        {['ton', 'not'].includes(to.value) && (
          <div className='ton-connect-wrapper'>
            <TonConnectButton className='ton-connect'/>
          </div>
        )}
        <div className='exchange-button modal-exchange-button' onClick={createTransaction}><span>Exchange</span></div>
      </div>
    )
  }

  useEffect(() => {
    if(userFriendlyAddress && modal) {
      let walletInput = document.getElementById('wallet')
      if(walletInput) {
        walletInput.value = userFriendlyAddress
      }
    }
  }, [userFriendlyAddress])

  useEffect(() => {
    const walletInput = document.getElementById('wallet');

    if(walletInput) {
      const defaultValue = (serverWallets.find(x => x.system === currencies.to.value))?.wallet

      walletInput.value = defaultValue || ''
    }
  }, [modal])

  const openOrderModal = (give, receive, from, to, method, number) => {
    setModal(
      <div className='modal__content'>
          <div className='back-page__title'>
              <div className='change__title history__title'>Order status</div>
          </div>
          <div className='lines'>
              <div className='line filled'></div>
              <div className='line'></div>
          </div>  
          <div className='order__text-wrapper'>
            <span className='order__number'>№{number}</span>
            <span className='order__status'>In the process</span>

            <div className='order__modal-info'>
              <span>You give away {give} {from}</span>
              <span>You receive {receive} {to}</span>
              <span>Withdraw to {method}</span>
            </div>
          </div>

          <div className='exchange-button modal-confirm-button' onClick={() => openSecondOrderModal(number, receive, to, method)}><span>Confirm transfer</span></div>
      </div>)
  }

  const openSecondOrderModal = (number, receive, value, fullname) => {
    const date = new Date().toLocaleString();
    setModal(
      <div className='modal__content'>
          <div className='back-page__title'>
            <div className='back__button' onClick={openModal(null)}>
              <img src={Arrow}/>
            </div>
            <div className='change__title history__title'>Order status</div>
          </div>
          <div className='lines'>
              <div className='line'></div>
              <div className='line filled'></div>
          </div>  
          <div className='order__text-wrapper'>
            <span className='order__number'>№{number}</span>
            <span className='order__status'>Successful</span>

            <div className='order__modal-info'>
              <span>You received {receive} {value} to {fullname}</span>
              <span>Time of sending: {date}</span>
            </div>
          </div>

          <a href="https://t.me/W_i_n_d_a_n" className='support__button'>Support</a>
      </div>)
  }

  return (
      <div>
        <Router active={panel} modal={modal}>
          <Hello go={go} id="hello"/>
          <Home id="home" meta={meta} openCreateTransactionModal={openCreateTransactionModal} serverWallets={serverWallets} openModal={openModal} go={go} currencies={currencies} updateCurrencies={updateCurrencies} openOrderModal={openOrderModal}/>
          <Settings id="settings" meta={meta} go={go} />
          <ChangeCurrency id="change" meta={meta} serverCurrencies={serverCurrencies} payload={payload} go={go} updateCurrencies={updateCurrencies} currencies={currencies}/>
          <Referral id="referral" meta={meta} go={go}/>
          <History id="history" meta={meta} go={go}/>
          <Default id="default" meta={meta} go={go}/>
          <FrensStat id="frensstat" meta={meta} go={go}/>
          <Withdraw id="withdraw" meta={meta} go={go} openModal={openModal} openWithdrawModal={openWithdrawModal}/>
          <Wallets id="wallets" serverWallets={serverWallets} meta={meta} go={go}/>
        </Router>
      </div>
  )
}

export default App;
