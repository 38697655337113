import React, { useState, useEffect } from 'react';
import axios from 'axios';

import './style.scss'

import Arrow from '../../assets/images/Arrow.svg'
import NOT from '../../assets/images/not.svg';
import SBP from '../../assets/images/sbp.png';
import Arrows from '../../assets/images/arrows.svg';
import Gear from '../../assets/images/gear.png';
import Telegram from '../../assets/images/telegram.svg';
import X from '../../assets/images/x.svg';

const Home = ({ currencies, openModal, go, openOrderModal, meta, openCreateTransactionModal }) => {

    async function getSum(amount, currency_from, currency_to) {
        let data = JSON.stringify({
            "user_id": meta.id,
            "amount": Number(amount),
            "currency_from": currency_from,
            "currency_to": currency_to,
            "service": ['RUB', 'KZT', 'UAH', 'USD', 'EUR'].includes(currency_from) ? 'ru' : 'sbp',
            "secret_key": "FWyd3fjtVwWN0AXkfFtxGWAsSHuj2w"
          });
        
        let config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: 'https://apicrypto.tech/get_sum_for_pay/',
          headers: { 
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
          },
          data : data
        };
        let response = await axios.request(config)
        console.log(Math.floor(response.data.data.endsum))
        const div2 = document.getElementById('result-amount');
        div2.innerHTML = response.data.data.endsum.toFixed(2)
        console.log(response.data.data)
      }

      async function createTransaction() {
        const wallet = document.getElementById('wallet');

        if(!wallet.value) return;

        let data = JSON.stringify({
            "secret_key": "FWyd3fjtVwWN0AXkfFtxGWAsSHuj2w",
            "amount": Number(document.getElementById('input-amount').innerHTML),
            "currency_from": currencies.from.name,
            "currency_to": currencies.to.name,
            "user_id": meta.id,
            "service": ['RUB', 'KZT', 'UAH', 'USD', 'EUR'].includes(currencies.from.name) ? 'ru' : 'sbp',
        });
        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: 'https://apicrypto.tech/transactions/trade',
            headers: { 
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*'
            },
            data : data
          };
          let response = await axios.request(config)

          const tx = response.data.data

          openOrderModal(Number(document.getElementById('input-amount').innerHTML), tx.endsum, currencies.from.name, currencies.to.name, currencies.to.fullname, tx.transaction_id)
      }

    useEffect(() => {
        const div = document.getElementById('input-amount');

        getSum(div.innerHTML, currencies.from.name, currencies.to.name)

        let storedValue = div.innerHTML

        function numberWithSpaces(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        }

        div.addEventListener('input', event => {
            let originalCursorPosition = getCaretPosition(div); // Сохраняем позицию курсора
        
            let sanitizedText = div.innerText.replace(/[^\d.]/g, ''); // Удаляем все символы кроме цифр и точки
            div.innerText = sanitizedText.replace(/^0+/, '');

            if(div.innerHTML.includes('.')){

                const floatValue = div.innerHTML.split('.')[1] || '';

                if(floatValue.length > 4) {
                    div.innerHTML = `${div.innerHTML.split('.')[0]}.${div.innerHTML.split('.')[1].slice(0,4)}}`
                }
            }

            const solidValue = div.innerHTML.split('.')[0];
                
            if(div.innerHTML.length == 13) {
                div.innerHTML = div.innerHTML.slice(1)
            }
            if(solidValue.length == 0) {
                div.innerHTML = `0`
            }


            if(event.inputType == 'deleteContentBackward') {
                if(storedValue[originalCursorPosition] == '.') {
                    div.innerHTML = storedValue.split('.')[0]
                }
            }

            setCaretPosition(div, originalCursorPosition);
            storedValue = div.innerHTML
            if(parseFloat(div.innerHTML) != 0) {
                getSum(parseFloat(div.innerText), currencies.from.name, currencies.to.name);
            } else {
                document.getElementById('result-amount').innerHTML = "0.00";
            }
        });
        
        function getCaretPosition(element) {
            let caretOffset = 0;
            const doc = element.ownerDocument || element.document;
            const win = doc.defaultView || doc.parentWindow;
            const sel = win.getSelection();
        
            if (sel.rangeCount > 0) {
                const range = sel.getRangeAt(0);
                const preCaretRange = range.cloneRange();
                preCaretRange.selectNodeContents(element);
                preCaretRange.setEnd(range.endContainer, range.endOffset);
                caretOffset = preCaretRange.toString().length;
            }
        
            return caretOffset;
        }
        
        function setCaretPosition(element, caretPos) {
            const textNode = element.firstChild;
            if (textNode !== null && textNode.nodeType === 3) {
                const range = document.createRange();
                range.setStart(textNode, Math.min(caretPos, textNode.length));
                range.setEnd(textNode, Math.min(caretPos, textNode.length));
                const sel = window.getSelection();
                sel.removeAllRanges();
                sel.addRange(range);
            }
        }

    }, [])

    return (
        <div className='hello__background flexbox'>
            <div className='change__content-wrapper'> 
                <div className='buttons__wrapper'>
                    <div className='main-button'><img src={Telegram} className='social-icon'/>Join Channel</div>
                    <div className='main-button'><img src={X} className='social-icon'/>Follow us</div>
                </div>
                <div className='change-block'>
                    <div>
                        <div className='change-block__title'>You give</div>
                        <div className='amount-input'>
                            <div contentEditable="true" id="input-amount" className='change-block__input'>10000</div>
                            <div className='change-block__currency'>{currencies.from.name}</div>
                        </div>
                    </div>
                    <div className='change-currency' onClick={go('change', 'from')}>
                        <img className='currency__icon' src={currencies.from.image}/>
                        <div className='currency__name'>{currencies.from.name}</div>
                        <img className='currency__arrow' src={Arrow}/>
                    </div>
                </div>

                <img className='arrows' src={Arrows}/>

                <div className='change-block'>
                    <div>
                        <div className='change-block__title'>You receive</div>
                        <div className='amount-input'>
                            <div className='change-block__input' id="result-amount">0</div>
                            <div className='change-block__currency'>{currencies.to.name}</div>
                        </div>
                    </div>
                    <div className='change-currency' onClick={go('change', 'to')}>
                        <img className='currency__icon' src={currencies.to.image}/>
                        <div className='currency__name'>{currencies.to.name}</div>
                        <img className='currency__arrow' src={Arrow}/>
                    </div>
                </div>

                <div className='exchange-button' onClick={() => openCreateTransactionModal(currencies.to, createTransaction)}><span>Exchange</span></div>

                <div className='frens-button' onClick={go('referral')}><span>Invite Frens</span></div>
                <div className='settings-button' onClick={go('settings')}>
                    <img src={Gear} className='gear'/>
                    <span>Settings</span>
                    <img src={Arrow} className='sidearrow'/>
                </div>
            </div>
        </div>
    )
}   

export default Home;