import React from 'react';

import './style.scss'

import Arrow from '../../assets/images/Arrow.svg';


const Settings = ({ go }) => {

    return (
        <div className='hello__background flexbox'>
            <div className='page-title'>Not settings</div>
            <div className='block flex-block centered'>
                <div className='settings__buttons-wrapper'>
                    <div className='exchange-button' onClick={go('referral')}><span>Referral program</span></div>
                    <div className='sub-button space' onClick={go('default')}><span>Currency</span></div>
                    <div className='sub-button' onClick={go('wallets')}><span>Wallets</span></div>
                    <div className='sub-button space' onClick={go('history')}><span>Transaction history</span></div>
                </div>
            </div>
            <a href="https://google.com" className='support__button'>Support</a>
            <div className='buttons__wrapper settings-buttons-space'>
            <div className='back-button settings-button-nav gray-button' onClick={go('home')}>
                    <img src={Arrow} className='back-arrow'/>
                    <span>Back</span>
                </div>

                <div className='main-button settings-button-nav gray-button'>FAQ</div>
            </div>
        </div>
    )
}   

export default Settings;