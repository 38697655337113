import React, { useState, useEffect } from 'react';
import { TonConnectButton, useTonWallet, useTonAddress } from '@tonconnect/ui-react';
import axios from 'axios';

import './style.scss'
import Arrow from '../../assets/images/Arrow.svg';


async function saveWallet(id, address, system) {
    let data = JSON.stringify({
        "secret_key": "FWyd3fjtVwWN0AXkfFtxGWAsSHuj2w",
        "user_id": id,
        "wallet": address,
        "system": system
      });
      
      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'https://apicrypto.tech/users/wallets/register/',
        headers: { 
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        },
        data : data
      };
      let response = await axios.request(config)
      console.log(response.data)
}

const Wallets = ({ go, meta, serverWallets }) => {

    const wallet = useTonWallet();
    const userFriendlyAddress = useTonAddress();
    let connectedWallet = ''

    useEffect(() => {
        if(connectedWallet != userFriendlyAddress) {
            console.log('connected',userFriendlyAddress)
            connectedWallet = userFriendlyAddress
            saveWallet(meta.id, userFriendlyAddress, 'ton');
            saveWallet(meta.id, userFriendlyAddress, 'not');
        }   
    }, [userFriendlyAddress])

    useEffect(() => {
        const usdt = document.getElementById('usdt')
        const phone = document.getElementById('phone')
        const card = document.getElementById('card')
        const yoo = document.getElementById('yoo')
        const pm = document.getElementById('pm')

        usdt.value = (serverWallets.find(x => x.system === 'usdt'))?.wallet || ''
        phone.value = (serverWallets.find(x => x.system === 'mobile'))?.wallet || ''
        card.value = (serverWallets.find(x => x.system === 'visa=ru'))?.wallet || ''
        yoo.value = (serverWallets.find(x => x.system === 'yoomoney'))?.wallet || ''
        pm.value = (serverWallets.find(x => x.system === 'perfect=us'))?.wallet || ''
    }, [])

    function saveWallets() {
        const usdt = document.getElementById('usdt')
        const phone = document.getElementById('phone')
        const card = document.getElementById('card')
        const yoo = document.getElementById('yoo')
        const pm = document.getElementById('pm')

        if(usdt.value && (serverWallets.find(x => x.system === 'usdt'))?.wallet != usdt.value) saveWallet(meta.id, usdt.value, 'usdt');

        if(phone.value && (serverWallets.find(x => x.system === 'sbp'))?.wallet != phone.value) saveWallet(meta.id, phone.value, 'sbp');
        if(phone.value && (serverWallets.find(x => x.system === 'mobile'))?.wallet != phone.value) saveWallet(meta.id, phone.value, 'mobile');

        if(card.value && (serverWallets.find(x => x.system === 'visa=ru'))?.wallet != card.value) saveWallet(meta.id, card.value, 'visa=ru');
        if(card.value && (serverWallets.find(x => x.system === 'visa=kz'))?.wallet != card.value) saveWallet(meta.id, card.value, 'visa=kz');
        if(card.value && (serverWallets.find(x => x.system === 'visa=ua'))?.wallet != card.value) saveWallet(meta.id, card.value, 'visa=ua');

        if(yoo.value && (serverWallets.find(x => x.system === 'yoomoney'))?.wallet != yoo.value) saveWallet(meta.id, yoo.value, 'yoomoney');
        if(pm.value && (serverWallets.find(x => x.system === 'perfect=us'))?.wallet != pm.value) saveWallet(meta.id, pm.value, 'perfect=us');
    }

    return (
        <div className='hello__background flexbox wallets__background'>
            <div className='back-page__title'>
                <div className='back__button' onClick={go('settings')}>
                    <img src={Arrow}/>
                </div>
                <div className='change__title history__title'>Wallets</div>
            </div>

            <div className='wallets__block-wrapper'>
                <span>USDT wallet</span>
                <input placeholder='Enter' className='wallets__input-text' id="usdt"/>
            </div>
            <div className='wallets__block-wrapper'>
                <span>Phone number</span>
                <input placeholder='Enter' className='wallets__input-text' id="phone"/>
            </div>
            <div className='wallets__block-wrapper'>
                <span>Bank Card</span>
                <input placeholder='Enter' className='wallets__input-text' id="card"/>
            </div>
            <div className='wallets__block-wrapper'>
                <span>Yoomoney</span>
                <input placeholder='Enter' className='wallets__input-text' id="yoo"/>
            </div>
            <div className='wallets__block-wrapper'>
                <span>Perfect money</span>
                <input placeholder='Enter' className='wallets__input-text' id="pm"/>
            </div>

            <div className='ton-connect-wrapper'>
                <TonConnectButton className='ton-connect'/>
            </div>

            <div className='settings-button copy-link withdraw-next save-wallet-button' onClick={() => saveWallets()}><span>Save wallets</span></div>
        </div>
    )
}   

export default Wallets;