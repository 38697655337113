import React, { useState } from 'react';

import './style.scss'

import DropdownMenu from '../../components/dropdown/component'

import Arrow from '../../assets/images/Arrow.svg';
import Check from '../../assets/images/check.png';

import USD from '../../assets/images/usd.png';
import EUR from '../../assets/images/eur.png';
import RUB from '../../assets/images/rub.png';

const Default = ({ go }) => {

    const options = [
        { value: 'rubles', label: 'RUB', sublabel: 'Russian ruble', image: RUB },
        { value: 'dollar', label: 'USD', sublabel: 'Dollar US', image: USD },
        { value: 'euro', label: 'EUR', sublabel: 'Euro', image: EUR }
      ];

      const [selectedOption, setSelectedOption] = useState(options[0]);

      const customRender = (option, subtitle = true) => (
        <div className="custom-option">
            {subtitle && (<img className='option__image' src={option.image}/>)}
            <div className='option__text-holder'>
                <span className='option__title'>{option.label}</span>
                {subtitle && (<span className='option__subtitle'>{option.sublabel}</span>)}
            </div>
            {(selectedOption.value === option.value && subtitle) && (<img className='check' src={Check}/>)}
        </div>
      );
    
      const handleSelect = (option) => {
        setSelectedOption(option);
      };

    return (
        <div className='hello__background flexbox'>
            <div className='default__background'>
                <div className='back-page__title'>
                    <div className='back__button' onClick={go('settings')}>
                        <img src={Arrow}/>
                    </div>
                    <div className='change__title history__title'>Default currency</div>
                </div>
                <div className='default__wrapper'>
                    <DropdownMenu className="dropdown-menu" options={options} defaultValue={options[0]} onSelect={handleSelect} customRender={customRender} />
                </div>
                <div className='settings-button copy-link default-save' onClick={go('settings')}><span>Save</span></div>
            </div>
        </div>
    )
}   

export default Default;