import React, { useState } from 'react';

import './style.scss'

import Arrow from '../../assets/images/Arrow.svg';

import DropdownMenu from '../../components/dropdown/component'

import Check from '../../assets/images/check.png';

const Withdraw = ({ go, openModal, openWithdrawModal }) => {

    const options = [
        { value: 'rubles', label: 'TON wallet', sublabel: 'Xx0342...23234', image: '' },
        { value: 'dollar', label: 'TON wallet', sublabel: 'Xx0342...23234', image: '' },
        { value: 'euro', label: 'TON wallet', sublabel: 'Xx0342...23234', image: '' }
      ];

      const [selectedOption, setSelectedOption] = useState(options[0]);

      const customRender = (option, subtitle = true) => (
        <div className="custom-option">
            {subtitle && (<img className='option__image' src={option.image}/>)}
            <div className='option__text-holder'>
                <span className='option__title'>{option.label}</span>
                {subtitle && (<span className='option__subtitle'>{option.sublabel}</span>)}
            </div>
            {(selectedOption.value === option.value && subtitle) && (<img className='check' src={Check}/>)}
        </div>
      );
    
      const handleSelect = (option) => {
        setSelectedOption(option);
      };

    return (
        <div className='hello__background flexbox'>
            <div className='page-title'>Referral</div>
            <div className='history__block withdraw__block'>
                <div className='history__title-wrapper withdraw__title'>
                <div className='back-page__title'>
                    <div className='back__button' onClick={go('referral')}>
                        <img src={Arrow}/>
                    </div>
                    <div className='change__title history__title'>Withdraw</div>
                </div>
                </div>
                    <div className='withdraw__dropdown-wrapper'>
                        <span className='select-wallet'>Select a wallet</span>
                        <DropdownMenu className="dropdown-menu" options={options} defaultValue={options[0]} onSelect={handleSelect} customRender={customRender} />
                    </div>
                    <div className='settings-button copy-link withdraw-next' onClick={openWithdrawModal(
                        <div className='modal__content'>
                            <div className='back-page__title'>
                                <div className='back__button' onClick={openModal(null)}>
                                    <img src={Arrow}/>
                                </div>
                                <div className='change__title history__title'>Withdraw</div>
                            </div>

                            <div className='withdraw__modal-info'>
                                <span>You receive 100 TON</span>
                                <span>Withdrawn to TON wallet</span>
                            </div>
                        </div>
                    )}><span>Next</span></div>
            </div>
        </div>
    )
}   

export default Withdraw;