import React, { useEffect, useState } from 'react';
import axios from 'axios';

import './style.scss'

import Arrow from '../../assets/images/Arrow.svg';

const History = ({ go, meta }) => {

    const [transactions, setTrans] = useState([])

    async function getHistory() {
        let data = JSON.stringify({
            "client_id": meta.id,
            "limit": 100,
            "offset": 0,
            "secret_key": "FWyd3fjtVwWN0AXkfFtxGWAsSHuj2w"
          });
        
        let config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: 'https://tegor05.fvds.ru/transactions/history',
          headers: { 
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
          },
          data : data
        };

        let response = await axios.request(config)
        console.log(response.data.data.transactions)
        setTrans(response.data.data.transactions)
      }

    
      useEffect(() => {
        getHistory()
      }, [])


    return (
        <div className='hello__background flexbox'>
            <div className='page-title'>Not history</div>
            <div className='history__block'>
                <div className='history__title-wrapper'>
                <div className='back-page__title'>
                    <div className='back__button' onClick={go('settings')}>
                        <img src={Arrow}/>
                    </div>
                    <div className='change__title history__title'>History</div>
                </div>
                </div>
                <div className='history__transactions-wrapper'>

                    {transactions.length != 0 ? transactions.map(x => <div className='history__transaction-block'>
                        <span className='history__transation-info'>№{x.id} {x.created_at.split(' ')[0]}: {x.amount_from} {x.currency_from} -> {x.amount_to} {x.currency_to}</span>
                        <span className={x.status}>{x.status.replace('_', ' ')}</span>
                    </div>) : <div className='empty-placeholder'>No one transaction...</div>}

                </div>
            </div>
        </div>
    )
}   

export default History;