import React from 'react';

import './style.scss'

import Arrow from '../../assets/images/Arrow.svg';
import NotBanner from '../../assets/images/banners/not.png'
import Mobile from '../../assets/images/banners/mobile.png'
import PmUsd from '../../assets/images/banners/pm-usd.png'
import SbpRub from '../../assets/images/banners/sbp-rub.png'
import Tether from '../../assets/images/banners/tether.png'
import Ton from '../../assets/images/banners/ton.png'
import VisaKZT from '../../assets/images/banners/visa-kzt.png'
import VisaUAH from '../../assets/images/banners/visa-uah.png'
import VisaRUB from '../../assets/images/banners/visa-rub.png'
import Yoomoney from '../../assets/images/banners/yoomoney.png'

import EU from '../../assets/images/countries/eu.png'
import KZ from '../../assets/images/countries/kz.png'
import RU from '../../assets/images/countries/ru.png'
import UA from '../../assets/images/countries/ua.png'
import US from '../../assets/images/countries/us.png'



import Check from '../../assets/images/whiteCheck.svg'
import { Router } from '../../components/Router/component'

const ChangeCurrency = ({ go, payload, updateCurrencies, currencies, serverCurrencies }) => {


    return (
        <div className='change__background flexbox'>
            <div className='back-page__title'>
                <div className='back__button' onClick={go('home')}>
                    <img src={Arrow}/>
                </div>
                <div className='change__title '>Choose a payment method</div>
            </div>
            <div className='scroll'>
                <Router active={payload}>
                <div id="from">
                    <div className='payment-method__banner' onClick={() => updateCurrencies({from: {name: "NOT", image: NotBanner, value: 'not'}})}>
                        <img src={NotBanner}/>
                        <div className='payment-method__text'>
                        NOT
                        </div>
                        {(currencies.from.value == 'not') && <img className='check payment-check' src={Check}/>}
                    </div>
                    <div className='payment-method__banner' onClick={() => updateCurrencies({from: {name: "TON", image: Ton, value: 'ton'}})}>
                        <img src={Ton}/>
                        <div className='payment-method__text'>
                        TON
                        </div>
                        {(currencies.from.value == 'ton') && <img className='check payment-check' src={Check}/>}
                    </div>
                    <div className='payment-method__banner' onClick={() => updateCurrencies({from: {name: "USDT", image: Tether, value: 'tether'}})}>
                        <img src={Tether}/>
                        <div className='payment-method__text'>
                        USDT
                        </div>
                        {(currencies.from.value == 'tether') && <img className='check payment-check' src={Check}/>}
                    </div>
                    <div className='payment-method__banner' onClick={() => updateCurrencies({from: {name: "RUB", image: RU, value: 'rub'}})}>
                        <img src={RU}/>
                        <div className='payment-method__text'>
                        RUB
                        </div>
                        {(currencies.from.value == 'rub') && <img className='check payment-check' src={Check}/>}
                    </div>
                    <div className='payment-method__banner' onClick={() => updateCurrencies({from: {name: "USD", image: US, value: 'usd'}})}>
                        <img src={US}/>
                        <div className='payment-method__text'>
                        USD
                        </div>
                        {(currencies.from.value == 'usd') && <img className='check payment-check' src={Check}/>}
                    </div>
                    <div className='payment-method__banner' onClick={() => updateCurrencies({from: {name: "EUR", image: EU, value: 'eur'}})}>
                        <img src={EU}/>
                        <div className='payment-method__text'>
                        EUR
                        </div>
                        {(currencies.from.value == 'eur') && <img className='check payment-check' src={Check}/>}
                    </div>
                    <div className='payment-method__banner' onClick={() => updateCurrencies({from: {name: "UAH", image: UA, value: 'uah'}})}>
                        <img src={UA}/>
                        <div className='payment-method__text'>
                        UAH
                        </div>
                        {(currencies.from.value == 'uah') && <img className='check payment-check' src={Check}/>}
                    </div>
                    <div className='payment-method__banner' onClick={() => updateCurrencies({from: {name: "KZT", image: KZ, value: 'kzt'}})}>
                        <img src={KZ}/>
                        <div className='payment-method__text'>
                        KZT
                        </div>
                        {(currencies.from.value == 'kzt') && <img className='check payment-check' src={Check}/>}
                    </div>
                </div>

                <div id="to">
                    {serverCurrencies.map(element =>
                        <div className='payment-method__banner' onClick={() => updateCurrencies({to: {name: element.currency, image: element.image, value: element.value, fullname: element.fullname}})}>
                            <img src={element.image}/>
                            <div className='payment-method__text'>
                            Blockchain fee 0%
                            </div>
                            {(currencies.to.value == element.value) && <img className='check payment-check' src={Check}/>}
                        </div>
                    )}
                </div>
                </Router>
            </div>
        </div>
    )
}   

export default ChangeCurrency;