import React, { useState } from 'react';

import './style.scss'

import DropdownMenu from '../../components/dropdown/component'

import Check from '../../assets/images/check.png';
import TON from '../../assets/images/banners/ton.png'
import Smile from '../../assets/images/smile.png';
import Arrow from '../../assets/images/Arrow.svg';

const Referral = ({ go }) => {

    const options = [
        { value: 'ton', label: 'TON', sublabel: 'Russian ruble' },
      ];

      const [selectedOption, setSelectedOption] = useState(options[0]);

      const customRender = (option, subtitle = true) => (
        <div className="custom-option">
            {subtitle && (<img className='option__image' src={option.image}/>)}
            <div className='option__text-holder'>
                <span className='option__title'>{option.label}</span>
            </div>
            {(selectedOption.value === option.value && subtitle) && (<img className='check' src={Check}/>)}
        </div>
      );
    
      const handleSelect = (option) => {
        setSelectedOption(option);
      };

    return (
        <div className='hello__background flexbox'>
            <div className='page-title'>Referral</div>
            <div className='ref__available-text'>Available for withdrawal</div>
            <div className='ref__amount-text'>3317 $</div>
            <div className='ref__block'>
                <div className='ref__block-content'>
                    <DropdownMenu className="dropdown-menu ref__dropdown" options={options} defaultValue={options[0]} onSelect={handleSelect} customRender={customRender} />
                    <div className='exchange-button ref__withdraw-button' onClick={go('withdraw')}><span>Withdraw</span></div>
                </div>
            </div>
            <div className='settings-button copy-link'><span>Copy link</span></div>
            <div className='settings-button' onClick={go('frensstat')}>
                    <img src={Smile} className='gear'/>
                    <span>Frens stats</span>
                    <img src={Arrow} className='sidearrow'/>
                </div>
            <div className='buttons__wrapper'>
                <div className='back-button settings-button-nav gray-button' onClick={go('settings')}>
                    <img src={Arrow} className='back-arrow'/>
                    <span>Back</span>
                </div>
                <div className='main-button settings-button-nav gray-button'>Refferal FAQ</div>
            </div>
        </div>
    )
}   

export default Referral;