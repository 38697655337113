import React, { useState } from 'react';

import './style.scss'

import Arrow from '../../assets/images/arrow.png'

const DropdownMenu = ({ options, onSelect, customRender, className, defaultValue }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(defaultValue);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    onSelect(option);
    setIsOpen(false);
  };

  return (
    <div className={className}>
      <div className="dropdown-toggle flex-container" onClick={() => setIsOpen(!isOpen)}>
          {customRender(selectedOption, false)}
          <img className={isOpen ? 'rotate arrow' : 'arrow'} src={Arrow}/>
      </div>
      {isOpen && (
        <div className="dropdown-options">
          {options.map(option => (
            <div
              key={option.value}
              className="dropdown-option"
              onClick={() => handleOptionClick(option)}
            >
              {customRender(option)}
            </div>
          ))}
          
        </div>
      )}
    </div>
  );
};

export default DropdownMenu;