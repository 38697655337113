import React from 'react';

import './style.scss'

import Arrow from '../../assets/images/Arrow.svg';
import Elon from '../../assets/images/elon.png'

const FrensStat = ({ go }) => {

    return (
        <div className='hello__background flexbox'>
            <div className='page-title'>Referral</div>
            <div className='history__block'>
                <div className='history__title-wrapper'>
                <div className='back-page__title'>
                    <div className='back__button' onClick={go('referral')}>
                        <img src={Arrow}/>
                    </div>
                    <div className='change__title history__title'>Frens</div>
                </div>
                <div className='stat__stats-wrapper'>
                    <div className='history__total'>Frens: 83</div>
                    <div className='history__total'>Total: 5056$</div>
                </div>
                </div>
                <div className='history__transactions-wrapper'>
                    <div className='stat__block'>
                        <img src={Elon}/>
                        <span className='stats__username'>@elonmusk</span>
                        <span className='stats__amount'>576 $</span>
                    </div>
                    <div className='stat__block'>
                        <img src={Elon}/>
                        <span className='stats__username'>@elonmusk</span>
                        <span className='stats__amount'>576 $</span>
                    </div>
                    <div className='stat__block'>
                        <img src={Elon}/>
                        <span className='stats__username'>@elonmusk</span>
                        <span className='stats__amount'>576 $</span>
                    </div>
                    <div className='stat__block'>
                        <img src={Elon}/>
                        <span className='stats__username'>@elonmusk</span>
                        <span className='stats__amount'>576 $</span>
                    </div>
                    <div className='stat__block'>
                        <img src={Elon}/>
                        <span className='stats__username'>@elonmusk</span>
                        <span className='stats__amount'>576 $</span>
                    </div>
                    <div className='stat__block'>
                        <img src={Elon}/>
                        <span className='stats__username'>@elonmusk</span>
                        <span className='stats__amount'>576 $</span>
                    </div>
                    <div className='stat__block'>
                        <img src={Elon}/>
                        <span className='stats__username'>@elonmusk</span>
                        <span className='stats__amount'>576 $</span>
                    </div>
                    <div className='stat__block'>
                        <img src={Elon}/>
                        <span className='stats__username'>@elonmusk</span>
                        <span className='stats__amount'>576 $</span>
                    </div>
                    <div className='stat__block'>
                        <img src={Elon}/>
                        <span className='stats__username'>@elonmusk</span>
                        <span className='stats__amount'>576 $</span>
                    </div>
                    <div className='stat__block'>
                        <img src={Elon}/>
                        <span className='stats__username'>@elonmusk</span>
                        <span className='stats__amount'>576 $</span>
                    </div>
                    <div className='stat__block'>
                        <img src={Elon}/>
                        <span className='stats__username'>@elonmusk</span>
                        <span className='stats__amount'>576 $</span>
                    </div>
                    <div className='stat__block'>
                        <img src={Elon}/>
                        <span className='stats__username'>@elonmusk</span>
                        <span className='stats__amount'>576 $</span>
                    </div>
                    <div className='stat__block'>
                        <img src={Elon}/>
                        <span className='stats__username'>@elonmusk</span>
                        <span className='stats__amount'>576 $</span>
                    </div>
                    <div className='stat__block'>
                        <img src={Elon}/>
                        <span className='stats__username'>@elonmusk</span>
                        <span className='stats__amount'>576 $</span>
                    </div>
                    <div className='stat__block'>
                        <img src={Elon}/>
                        <span className='stats__username'>@elonmusk</span>
                        <span className='stats__amount'>576 $</span>
                    </div>
                    <div className='stat__block'>
                        <img src={Elon}/>
                        <span className='stats__username'>@elonmusk</span>
                        <span className='stats__amount'>576 $</span>
                    </div>
                    <div className='stat__block'>
                        <img src={Elon}/>
                        <span className='stats__username'>@elonmusk</span>
                        <span className='stats__amount'>576 $</span>
                    </div>

                </div>
            </div>
        </div>
    )
}   

export default FrensStat;